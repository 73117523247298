<template>
  <div class="game_details">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <router-link :to="{name:'AllGame'}">&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;全部游戏</router-link>
      <router-link :to="{name:'SelectGame',query:{game_id:this.$route.query.game_id},params:{productTypeSelect:3}}">&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;{{game_name}}</router-link>
      <router-link :to="{name:'SelectGame',query:{game_id:this.$route.query.game_id},params:{productTypeSelect:3}}">&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;游戏金币</router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;金币详情</a>
    </div>
    <div class="details">
      <div class="details_info">
        <div class="DI_base_img">
          <div slot="reference">
            <el-image
              style="width: 420px; height: 420px;border-radius: 10px"
              :src="accountInfo.image"
              fit="cover"
              :preview-src-list="srcList">
              <div slot="placeholder" class="image-slot">
                图片加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </div>
        <div class="DI_base_content">
          <div class="DI_product_title">
            <div class="DIP_title">
              <div class="DIP_title_top">
                <span>{{ accountInfo.game_name }}</span>{{ accountInfo.name }}
              </div>
              <div class="DIP_title_bot">
                <div>金额换算：</div>
                <div class="DIP_title_convert">1元={{ accountInfo.sell_price }}<span v-show="accountInfo.unit == '2'">万</span>金</div>
                <div class="DIP_title_convert">1<span v-show="accountInfo.unit == '2'">万</span>金={{ accountInfo.unit_price }}元</div>
              </div>
            </div>
            <div class="collect">
              <div v-if="!accountInfo.is_collect" @click="collectClick" class="collectBtn" @mouseenter="collectBtn = true" @mouseleave="collectBtn=false">
                <img v-show="!collectBtn" src="@/assets/image/AllGame/collect.png" alt="">
                <img v-show="collectBtn" src="@/assets/image/AllGame/collectHover.png" alt="">
                收藏
              </div>
              <div v-else @click="collectClick" class="isCollectBtn" >
                <img src="@/assets/image/AllGame/isCollect.png" alt="">
                已收藏
              </div>
            </div>
          </div>
          <div class="DI_prodct_price">
            <div id="DI_prodct_price">
              商品价格：
              <div>¥{{ accountInfo.sell_price }}</div>
            </div>
            <div id="DI_prodct_hot">
              <img src="@/assets/image/Home/topGamesIco.png" alt="">
              <span>{{ accountInfo.click }}</span>
            </div>
          </div>
          <div class="DI_prodct_zone">
            <div v-for="(categoryItem,index) in accountInfo.category" :key="index" id="DI_prodct_zone">
              <div class="zone_title">
                {{ categoryItem.name.toString().slice(1,categoryItem.name.toString().length) }}：
              </div>
              <div class="zone_content">{{ categoryItem.value }}</div>
            </div>
          </div>
          <div class="DI_prodct_addDate">
            <div>
              库存：
              <span>{{ accountInfo.num }}</span>
            </div>
            <div>
              上架时间：
              <span>{{ accountInfo.add_time|moment }}</span>
            </div>

          </div>
          <div class="DI_btnGroup">
            <div class="DIBG_buyNum">
              购买数量：
              <el-input-number v-model="num" :min="1" :max="accountInfo.num" label="描述文字"></el-input-number>
            </div>
            <a target="_blank" :href="accountInfo.game_customer" class="onlineDealBtn">立即购买</a>
            <a class="cusSerBtn" target="_blank" :href="accountInfo.game_customer">
              <div>咨询客服</div>
              <div class="cusSerBtn_tip">跳转失败联系企业 QQ：800178677</div>
            </a>
            <!--        <router-link :to="{name:'OnlineDeal',query:{id:id,game_id:game_id}}" class="onlineDealBtn">立即购买</router-link>-->
          </div>
        </div>
      </div>
      <div class="DI_productName">
        <div id="DI_productName">
          <div class="DI_productName_title">
            <img src="@/assets/image/PersonalCenter/MyAccount/contentItemTitle.png" alt="">
            商品描述
          </div>
          <div class="DI_productName_text">
            {{accountInfo.content}}
          </div>
        </div>
      </div>
      <div class="details_tabbar">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="交易须知" name="first">
            <div class="third_content">
              <div v-html="accountInfo.game_notice"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="交易流程" name="second">
            <div class="third_content">
<!--              <div v-html="accountInfo.game_introduce"></div>-->
            </div>
          </el-tab-pane>
          <el-tab-pane label="业务介绍" name="third">
            <div class="third_content">
              <div v-html="accountInfo.game_introduce"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="交易群" name="fourth">
            <div class="fourth_content">
              <div class="fourth_content_item" v-for="(item,index) in qqGrounp" :key="index">
                <div class="fourth_content_item_box" >
                  <div>
                    <img src="@/assets/image/AllGame/contentItemTitle.png" alt="">
                  </div>
                  <div class="qq_logo">
                    <img src="@/assets/image/AllGame/QQlogo.png" alt="">
                  </div>
                  <div class="group_name">{{ item.name }}</div>
                  <a class="add_group" target="_blank" :href="item.link">点击加群</a>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {apiGoldInfo, apiCollectGold, apiBargainAdd,apiBargainClcik} from "@/request/API";

export default {
  name: '',
  components: {

  },
  data () {
    return{
      //游戏名称
      game_name:'',
      //游戏id
      game_id:this.$route.query.game_id,
      //账号id
      id:this.$route.query.id,
      // 当前选中tabbar
      activeName:'first',
      // 交易qq群
      qqGrounp:[],
      //账号详情
      accountInfo:{
        image:''
      },
      //购买数量
      num:'',
      can_show:true,
      srcList:[],
      collectBtn:false,
      priceCutBtn:false,
    }
  },
  methods: {
    handleClick(tab, event){
    },
    /**
     * 收藏按钮
     */
    collectClick(){
      if(this.$store.state.loginStatus){
        apiCollectGold({
          game_id:this.game_id,
          acc_id:this.id,
        }).then(res=>{
          this.accountInfo.is_collect = res.data.is_collect
        }).catch(err=>{
          this.$message({
            type:"error",
            center:true,
            message:err.msg
          })
        })
      }else {
        this.$message({
          type:"error",
          message:'尚未登录，请先登录。',
          center:true
        })
        this.$router.push({
          path:'/login',
          query:{
            Rurl: this.$route.fullPath,
            game_name:this.game_name,
            game_id:this.game_id,
            id:this.id,
          },
        })
      }
    },
    seeking_price_cuts_confirm(){
      apiBargainClcik({
        game_id:this.game_id,
        id:this.id,
      }).catch(err=>{
        this.$message({
          type:"error",
          message:err.msg,
          center:true
        })
      })
      if(this.$store.state.loginStatus){
        let sell_price = 'block'
        if (this.accountInfo.sell_price == '0.00') {
          sell_price = 'none'
        }
        const h = this.$createElement;
        if(this.accountInfo.is_bargain){
          this.$msgbox({
            title:'',
            message: h('div', {class:'confirm_content'}, [
              h('div', {class:'confirm_original_price'}, '原价：'+this.accountInfo.price+''),
              h('div', {class:'confirm_my_bid'}, '我的出价：'+this.accountInfo.bargain_price+''),
              h('div', {class:'confirm_my_bid',style:"display:"+sell_price+""}, '卖家最低报价：'+this.accountInfo.sell_price+''),
              h('div',{class:'confirm_modify_bid'}, [
                h('div', {class:'confirm_modify_bid_text'}, '修改出价：'),
                h('input', {class:'confirm_modify_bid_input',attrs:{placeholder: "输入修改后的价格",id:"confirm_modify_bid_input"}}),
              ]),
              h('div', {class:'confirm_modify_bid_tips'},'（提示：24小时内只能求降价1次）'),
            ]),
            confirmButtonText: '确认修改',
            confirmButtonClass:'change_price_confirm_btn',
            customClass: 'change_price_confirm',
            closeOnClickModal:false,
            showClose:true,
            callback: action => {
              if(action === "confirm"){
                apiBargainAdd({
                  id:this.accountInfo.bargain_id,
                  price:document.getElementById("confirm_modify_bid_input").value
                }).then(res=>{
                  this.$message({
                    message:"修改成功",
                    center:true,
                    type:'success'
                  })
                }).catch(err=>{
                  this.$message({
                    message:err.msg,
                    center:true,
                    type:'error'
                  })
                })
              }
              document.getElementById("confirm_modify_bid_input").value = ''
            }
          });
        }else{
          this.$msgbox({
            title:'',
            message: h('div', {class:'confirm_content'}, [
              h('div', {class:'confirm_original_price'}, '原价：'+this.accountInfo.price+''),
              h('div',{class:'confirm_modify_bid'}, [
                h('div', {class:'confirm_modify_bid_text'}, '我的出价：'),
                h('input', {class:'confirm_modify_bid_input',attrs:{placeholder: "输入我出的价格",id:'confirm_modify_bid_input'}}),
              ]),
              h('div', {class:'confirm_modify_bid_tips'},'（提示：24小时内只能求降价1次）'),
            ]),
            confirmButtonText: '确认发起',
            confirmButtonClass:'change_price_confirm_btn',
            customClass: 'add_price_confirm',
            closeOnClickModal:false,
            showClose:true,
            callback: action => {
              if(action === "confirm"){
                apiBargainAdd({
                  game_id:this.game_id,
                  product_id:this.id,
                  price:document.getElementById("confirm_modify_bid_input").value
                }).then(res=>{
                  this.$message({
                    center:true,
                    message:"发起降价请求成功",
                    type:'success'
                  })
                  this.accountInfo.is_bargain = true
                  this.accountInfo.bargain_price = res.data.bargain_price

                }).catch(err=>{
                  this.$message({
                    message:err.msg,
                    center:true,
                    type:'error'
                  })
                })
              }
              document.getElementById("confirm_modify_bid_input").value = ''
            }
          });
        }
      }else {
        this.$message({
          type:"error",
          message:'尚未登录，请先登录。',
          center:true
        })
        this.$router.push({
          path:'/login',
          query:{
            Rurl: this.$route.fullPath,
            game_name:this.game_name,
            game_id:this.game_id,
            id:this.id,
          },
        })
      }

    },
    //初始化数据
    onLoad(){
      apiGoldInfo({
        id:this.id,
      }).then(res=>{
        this.can_show = res.data.can_show
        this.accountInfo = res.data
        this.srcList.push(res.data.image)
        this.game_name = res.data.game_name
        this.qqGrounp = res.data.game_qun
        document.title= this.accountInfo.name
      })
    },
  },
  mounted () {
    //初始化数据
    this.onLoad()
  },
  watch: {
  },
  computed: {

  },
  created() {
    document.title = ''
  },
  beforeRouteLeave(to,from,next){
    if (to.name !== 'GoldDetails') {
      document.title = 'uushouyou网络游戏账号交易代售平台-签署合同让交易享受法律保护'
      next()
    }
  }

}
</script>

<style lang="less" >
.first_content img,.second_content img{
  max-width: 100%;
  object-fit: cover;
  background: #D8D8D8;
  border-radius: 20px;
  margin: 0 0 9px 0;
}
.add_price_confirm{
  width: 500px !important;
  height: 300px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;
  .el-message-box__header>.el-message-box__title{
    display: none;
  }
  .el-message-box__header,.el-message-box__content{
    display: flex;
    flex-direction: column;
    padding: 0 0 0 70px;
  }
  .el-message-box__btns{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .el-message-box__title{
    margin-top: 38px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }
  .el-message-box__container{
    margin-top: 63px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }
  .change_price_confirm_btn{
    margin-top: 20px;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }
  .change_price_confirm_btn:hover{
    background: #FFDB51 !important;
  }
  .confirm_content{
    display: flex;
    flex-direction: column;
  }
  .confirm_original_price{
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FC4A00;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .confirm_my_bid{
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .confirm_modify_bid{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .confirm_modify_bid_text{
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
  }
  .confirm_modify_bid_input{
    width: 210px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 20px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    line-height: 22px;
  }
  .confirm_modify_bid_tips{
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #BFBFBF;
    line-height: 22px;
    margin-left: 100px;
  }
  .confirm_modify_bid_input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #D1D1D1;
  }
}
.change_price_confirm{
  width: 500px !important;
  height: 320px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;
  .el-message-box__header>.el-message-box__title{
    display: none;
  }
  .el-message-box__header,.el-message-box__content{
    display: flex;
    flex-direction: column;
    padding: 0 0 0 70px;
  }
  .el-message-box__btns{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .el-message-box__title{
    margin-top: 38px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }
  .el-message-box__container{
    margin-top: 34px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }
  .change_price_confirm_btn{
    margin-top: 20px;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }
  .change_price_confirm_btn:hover{
    background: #FFDB51 !important;
  }
  .confirm_content{
    display: flex;
    flex-direction: column;
  }
  .confirm_original_price{
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FC4A00;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .confirm_my_bid{
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .confirm_modify_bid{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .confirm_modify_bid_text{
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
  }
  .confirm_modify_bid_input{
    width: 210px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 20px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    line-height: 22px;
  }
  .confirm_modify_bid_tips{
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #BFBFBF;
    line-height: 22px;
    margin-left: 100px;
  }
  .confirm_modify_bid_input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #D1D1D1;
  }
}
</style>

<style lang="less" scoped>
/deep/ .el-tabs__nav-wrap::after{
  content: none;
}
/deep/ .el-tabs__item{
  font-size: 20px;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 300;
  color: #000000;
}
/deep/ .el-tabs__item.is-active{
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
}
/deep/.el-tabs__active-bar.is-top{
  background: #FFFFFF linear-gradient(90deg, transparent 20px, #FCAD13 20px, #FCAD13 calc(100% - 20px), transparent calc(100% - 20px));
  //width: 20% !important;
  padding:0 20px;
  box-sizing: border-box;
  background-clip: content-box;
  //background: #FCAD13;
  border-radius: 4px;
}
/deep/.el-tabs__header{
  margin: 0 0 40px;
}
.game_details{
  width: 1200px;
  .title{
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    a{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      .Breadcrumb{
        font-size: 12px;
      }
    }
    a:last-of-type{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FCAD13;
      cursor: pointer;
    }
  }
  .details{
    display: flex;
    flex-direction: column;
    .details_info{
      padding:20px 40px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: row;
      .DI_base_img{
        margin: 0 20px 0 0;
      }
      .DI_base_content{
        width: 680px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .DI_product_title{
          padding: 0 0 0 20px;
          background: #FFFFFF;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #5B5B5B;
          line-height: 34px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .DIP_title{
            width: 513px;
            .DIP_title_top{
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #000000;
              line-height: 28px;
              margin: 0 0 10px 0;
              span{
                background: #FCAD13;
                border-radius: 20px;
                font-size: 16px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                padding: 2px 13px;
                margin: 0 10px 0 0;
              }
            }
            .DIP_title_bot{
              display: flex;
              flex-direction: row;
              font-size: 18px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #FCAD13;
              line-height: 25px;
              .DIP_title_convert{
                margin: 0 10px;
              }
            }
          }
          .collect{
            display: flex;
            align-items: center;
            .collectBtn{
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 105px;
              height: 40px;
              border-radius: 4px;
              border: 1px solid #FCAD13;
              font-size: 16px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #FCAD13;
              img{
                margin: 0 3px 0 0;
              }
            }
            .isCollectBtn{
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 105px;
              height: 40px;
              border-radius: 4px;
              border: 1px solid #FCAD13;
              font-size: 16px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #FFFFFF;
              background: #FCAD13;
              img{
                margin: 0 3px 0 0;
              }
            }
            .collectBtn:hover{
              background: #FFF6E4;
            }
          }

        }
        .DI_prodct_price{
          height: 80px;
          background: url("../../../assets/image/AllGame/goldDetailsInfo.png") no-repeat ;
          padding: 0 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          #DI_prodct_price{
            display: flex;
            align-items: center;
            flex-direction: row;
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            margin: 0 10px 0 0;
            div{
              font-size: 30px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FC4A00;
              margin: 0 10px 0 0;
              max-width: 250px;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }
          }
          #DI_prodct_hot{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;
            img{
              margin: 0 6px 0 0;
              width: 17.38px;
              height: 20px;
            }
          }
        }
        .DI_prodct_zone{
          min-height: 30px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 10px 0 ;
          padding:0 20px;
          #DI_prodct_zone{
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 10px 0;
            .zone_title{
              max-width: 84px;
              min-width: 56px;
              font-size: 14px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #8F8F8F;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .zone_content{
              width: 118px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
              background: url("../../../assets/image/AllGame/gamingZone.png") no-repeat ;
            }
          }
        }
        .DI_prodct_promise{
          padding: 10px 0 11px  20px;
          border-top: 1px solid #F1F1F1;
          border-bottom: 1px solid #F1F1F1;
          display: flex;
          flex-direction: row;
          align-items: center;
          .promise_title{
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            line-height: 20px;
            margin: 0 16px 0 0;
          }
          .promise_list{
            display: flex;
            flex-direction: row;
            .promise_item{
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #BFBFBF;
              margin: 0 40px 0 0;
              img{
                margin: 0 4px 0 0;
              }
            }
            .promise_item:last-of-type{
              margin: 0;
            }
          }
        }
        .DI_prodct_addDate{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-top: 1px solid #F1F1F1;
          border-bottom: 1px solid #F1F1F1;
          padding: 19px 0;
          margin: 0 0 19px 20px;
          div{
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            span{
              color: #5B5B5B;
            }
          }
        }
        .DI_btnGroup{
          height: 60px;
          padding: 0 0 0 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          div{
            cursor: pointer;
          }
          .cusSerBtn{
            margin: 0  0 0 10px;
            width: 218px;
            height: 58px;
            border-radius: 30px;
            border: 1px solid #283748;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
            .cusSerBtn_tip{
              font-size: 12px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
            }
          }
          .onlineDealBtn{
            width: 220px;
            height: 60px;
            background: #FFDB51;
            border-radius: 30px;
            font-size: 20px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #000000;
            line-height: 60px;
            text-align: center;
          }
          .DIBG_buyNum{
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            margin: 0 20px 0 0 ;
            /deep/.el-input-number{
              width: 120px;
            }
            /deep/.el-input__inner{
              padding: 0 30px;
              border: 1px solid #EFEFEF !important;
            }
            /deep/.el-input-number__decrease,/deep/.el-input-number__increase{
              width: 30px;
              border-color: #EFEFEF !important;
              background: #FFFBEF !important;
              color: #909399 !important;
            }

          }
        }
      }
    }
    .DI_productName{

      //background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      background: #FFFFFF;
      width: 1200px;
      #DI_productName{
        background: url("../../../assets/image/AllGame/productNameBackground.png") no-repeat;
        width: 1120px;
        padding: 20px 40px;
        .DI_productName_title{
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #FF9407;
          line-height: 28px;
          margin: 0 0 10px;
          img{
            margin: 0 20px 0 0;
          }
        }
        .DI_productName_text{
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
          line-height: 22px;
        }
      }

    }
    .DI_links{
      width: 1120px;
      height: 40px;
      background: #FFFFFF;
      padding: 20px 40px;
      margin: 0 0 10px 0;
      .labels{
        display: flex;
        flex-direction: row;
        a{
          flex: 1;
          text-align: center;
          margin: 0 11px 0 0;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #BFBFBF;
          padding: 11px 10px;
          border-radius: 10px;
          border: 1px solid #E2E2E2;
        }
        a:last-of-type{
          margin: 0;
        }
        a:hover{
          color: #FCAD13;
          background: #FFF6E4;
          border: 1px solid #FFF6E4;
        }
      }
    }
    .details_tabbar{
      padding: 40px 40px 58px 40px;
      background-color: #FFFFFF;
      .first_content{
        width: 1120px;
        margin-bottom: 9px;
      }
      .second_content{
        width: 1120px;
        margin-bottom: 9px;
      }
      .third_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .third_content_item{
          margin-bottom: 50px;
          .content_item_title{
            display: flex;
            flex-direction: row;
            margin-bottom: 21px;
            div:first-of-type{
              margin-right: 20px;
            }
            div:last-of-type{
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FF9407;
            }
          }
          .content_item_content{
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
            line-height: 25px;
            img{
              width: 1120px;
              height: 128px;
              object-fit: cover;
            }
          }
        }
        .third_content_item:last-of-type{
          margin-bottom: 0;
        }
        .third_content_item:first-of-type{
          margin-bottom: 41px;
        }
      }
      .fourth_content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        .fourth_content_item{
          width: 50%;
          .fourth_content_item_box{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 15px 0;
            div:first-of-type{
              margin-right: 20px;
            }
            .qq_logo{
              margin-right: 10px;
            }
            .group_name{
              font-size: 20px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #000000;
              line-height: 28px;
              margin-right: 30px;
            }
            .add_group{
              width: 100px;
              height: 40px;
              background: linear-gradient(
                180deg
                , #FFDB51 0%, #FF8435 100%);
              border-radius: 20px;
              font-size: 20px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 40px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .showFalse{
    width: 300px;
    //height: 285px;
    background: #FFFFFF;
    padding: 50px 450px 70px;
    .showFalse_tip{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 39px 0;
      img{
        width: 229px;
        height: 150px;
        object-fit: cover;
        //margin: 0 0 6px 0;
      }
      #tip{
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #9A9A9A;
        line-height: 20px;
      }
    }
    .showFalse_btnG{
      display: flex;
      flex-direction: column;
      align-items: center;
      a{
        width: 300px;
        height: 50px;
        border-radius: 30px;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      #back{
        background: #FFDB51;
        margin: 0 0 8px 0;
      }
      #cusSer{
        border: 1px solid #000000;
      }
    }
  }

}

</style>
